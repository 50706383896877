.Chat {
	text-align: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	max-width: 100%;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

@media screen and (min-width: 768px) {
	.Chat-header {
		/* background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	/* justify-content: center;  */
		/*font-size: calc(10px + 2vmin);
	color: white; */

		background-color: #282c34;
		display: flex;
		flex-direction: row;
		/* Change to row on larger screens */
		align-items: center;
		justify-content: center;
		font-size: calc(10px + 2vmin);
		/* Adjust based on screen size */
		color: white;
		/*padding: 20px;
	/* Added padding for better spacing */

	}




	#sidebar {
		width: 35%;
		height: 100vh;
		background-color: rgb(26, 25, 25);
		color: white;
		overflow-y: auto;
		overflow-x: 'hidden';
	}

	#main_content {
		height: 100vh;
		overflow-y: auto;
		width: 65%;
		position: relative;
		scroll-behavior: smooth;
	}

}



.item {
	width: 100%;
	background-color: rgb(49, 45, 45);
}

.item:last-child {
	margin-bottom: 50vh;
}

.logo_item {
	width: 35px;
	height: 35px;
	margin-right: 10px;
}

.question {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: rgba(59, 80, 87);
	padding: 30px;
	height: 0in;

}

.question .value {
	text-align: right;
	width: 80%;
	font-size: 0.2in;
}

.answer {
	color: black;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: whitesmoke;
	padding: 30px;
}

.answer .value {
	text-align: justify;
	font-size: 0.2in;
}

.item_container {
	width: 80%;
	display: flex;
	flex-direction: row;
}

.question .item_container {
	justify-content: flex-end;
}

.question .logo_container {
	margin-left: 10px;
}

.answer .item_container {
	justify-content: flex-start;
}

.input_src {
	position: fixed;
	bottom: 0;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1));
	width: 70%;
	height: 20vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.input-container {
	margin: 0;
	padding: 20px;
	width: 70%;
	display: flex;
	flex-direction: row;
}

.input-container input[type="text"] {
	background-color: white;
	width: 90%;
	padding: 20px;
	font-size: 16px;
	border-radius: 4px 0 0 4px;
	border: 1px solid #ccc;
	outline: none;
}

.input-container button {
	font-size: 16px;
	border-radius: 0 4px 4px 0;
	border: none;
	background-color: #007bff;
	color: #fff;
	cursor: pointer;
	outline: none;
	padding: 20px;
}

button:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}


/* App.css */

.results .pdf-viewer {
	overflow-x: 'hidden';
	/* Add your CSS styles here */
	width: 100%;
	/* Adjust the width as needed */
	/* height: 500px; Adjust the height as needed */
	border: 1px solid #ccc;
	/* overflow: auto; */
	/* margin-top: 20px; */
	/* background-color: red; */
	/* Add more styles as desired */
}


.results table {
	background-color: red;
	width: 100%;
	padding: 10px;
	margin-top: 10vh;
}

.file-item {
	font-size: 0.9em;
	padding: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-align: left;
	margin-bottom: 15px;
}

.file-item button {
	padding: 5px;
	height: 30px;
	background-color: red;
}



.process {
	padding: 5px;
	height: 30px;
	background-color: green;
}

.results table td {
	text-align: left;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.chat-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 20px;
	box-sizing: border-box;
}

.chat-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.message-list {
	flex-grow: 1;
	overflow-y: auto;
	padding-right: 10px;
}

.message-item {
	display: inline-block;
	padding: 8px 16px;
	margin-bottom: 10px;
	border-radius: 4px;
	color: #fff;
	font-size: 16px;
	max-width: 70%;
}

.user {
	background-color: #007bff;
	align-self: flex-start;
}

.chatbot {
	background-color: #28a745;
	align-self: flex-end;
}

.feedback {
	display: flex;
	flex-direction: row;
	height: 40px;
	width: 100%;
	padding: 20px;

}

.feedback .icons {
	width: 15%;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
}

.feedback .icons .icon {
	margin-right: 10px;
}

.feedback form {
	display: flex;
	flex-direction: row;
	width: 90%;
	height: 100%;
}

.feedback form>input {
	width: 75%;
	background-color: white;
	padding: 10px;
	font-size: 16px;
	border-radius: 4px 0 0 4px;
	border: 1px solid #ccc;
	outline: none;
	margin-right: 15px;
}

.feedback form>button {
	width: 25%;
	font-size: 16px;
	border-radius: 0 4px 4px 0;
	border: none;
	background-color: #898e94;
	color: #fff;
	cursor: pointer;
	outline: none;
}