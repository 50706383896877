/* Login.css */
.main-body {
	background-image: url('./images/background.png');
	background-size: cover;
	/* Cover the entire container */
	background-position: center;
	/* Center the image in the container */
	background-repeat: no-repeat;
	/* Do not repeat the image */
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	/* Ensures the element covers the entire viewport height */
	margin: 0;
	/* Resets default margin */
}

.login-logo {
	display: block;
	margin: 0 auto 20px auto;
	/* Center the logo and add some margin below */
	max-width: 100px;
	/* Adjust the size as needed */
	height: auto;
	/* Maintain aspect ratio */
}

/* Updated styles for .login-container */
.login-container {
	max-width: 400px;
	width: 90%;
	/* Now uses a percentage to adapt to screen size */
	margin: auto;
	/* Centers the container */
	padding: 20px;
	border: 2px solid #261cb8;
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(204, 11, 11, 0.1);
	background-color: #FFFFFF;
	box-sizing: border-box;
	/* Ensures padding doesn't affect overall width */
}

/* Media query for larger screens */
@media (min-width: 768px) {
	.login-container {
		width: 50%;
		/* Slightly wider on larger screens but still responsive */
	}
}

/* Adjusting the media query for very large screens */
@media (min-width: 1024px) {
	.login-container {
		width: 30%;
		/* Sets a more readable width on very large screens */
	}
}

.login-container h2 {
	text-align: center;
	margin-bottom: 20px;
}

.form-group {
	margin-bottom: 20px;
}

.form-group label {
	display: block;
	margin-bottom: 5px;
}

.form-group input {
	width: 100%;
	padding: 8px 10px;
	border: 1px solid #ddd;
	/* Lightened the border color for a softer look */
	border-radius: 4px;
	box-sizing: border-box;
}

.form-group input:focus {
	outline: none;
	border-color: #007bff;
}

.error {
	color: red;
	font-size: 0.85em;
	margin-top: 5px;
}

.form-group button[type="submit"] {
	width: 100%;
	padding: 10px 15px;
	border: none;
	border-radius: 4px;
	background-color: #2971bd;
	color: white;
	cursor: pointer;
	transition: background-color 0.2s;
}

.form-group button[type="submit"]:hover {
	background-color: #0056b3;
}

.form-group button[type="submit"]:focus {
	outline: none;
}