/* Base button style */
.buttonStyle {
	border: 0.5px solid transparent;
	/* Invisible border for consistent spacing */
	/* padding: 10px; */
	background: none;
	cursor: pointer;
}

/* Hover effect for buttonStyle */
.buttonStyle:hover {
	background-color: #202020;
	/* Light grey background on hover */
	/* border-color: #ddd; */
	/* Darker border on hover */
}

/* Thumbs up button style */
/* .thumbsUpStyle {
	border-color: green;
} */

/* Thumbs down button style */
/* .thumbsDownStyle {
	border-color: red;
} */

/* Active state styling for buttons, assuming you want some common active style */
/* .icon-button.active {
	// Example: Add some active state styles here, e.g., a shadow 
	box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
} */

/* Input style for non-submittable state */
.inputStyleNotSubmittable {
	border-color: red;
}